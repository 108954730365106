// extracted by mini-css-extract-plugin
export var center = "styles-module--center--c0f7c";
export var hide = "styles-module--hide--8598f";
export var modified = "styles-module--modified--e1ea8";
export var swiper = "styles-module--swiper--21e67";
export var swiper3d = "styles-module--swiper-3d--0351d";
export var swiperAndroid = "styles-module--swiper-android--4eefc";
export var swiperAutoheight = "styles-module--swiper-autoheight--0ce6b";
export var swiperBackfaceHidden = "styles-module--swiper-backface-hidden--14f86";
export var swiperCentered = "styles-module--swiper-centered--41703";
export var swiperCssMode = "styles-module--swiper-css-mode--d3e38";
export var swiperCubeShadow = "styles-module--swiper-cube-shadow--c78be";
export var swiperHorizontal = "styles-module--swiper-horizontal--5d4a3";
export var swiperPointerEvents = "styles-module--swiper-pointer-events--9cf7b";
export var swiperSlide = "styles-module--swiper-slide--b1f42";
export var swiperSlideInvisibleBlank = "styles-module--swiper-slide-invisible-blank--0d8d4";
export var swiperSlideShadow = "styles-module--swiper-slide-shadow--bf152";
export var swiperSlideShadowBottom = "styles-module--swiper-slide-shadow-bottom--1601a";
export var swiperSlideShadowLeft = "styles-module--swiper-slide-shadow-left--82765";
export var swiperSlideShadowRight = "styles-module--swiper-slide-shadow-right--e314f";
export var swiperSlideShadowTop = "styles-module--swiper-slide-shadow-top--df8f2";
export var swiperVertical = "styles-module--swiper-vertical--69f2e";
export var swiperWrapper = "styles-module--swiper-wrapper--76c7e";