// import './src/styles/styles.module.scss';
require('./src/styles/styles.module.scss');

exports.onRouteUpdate = () => {
  // ACCORDIONS.JS
  // Remove existing script if it exists
  const existingScript = document.querySelector("script[src='/assets/js/accordions.js']");
  if (existingScript) {
    existingScript.remove();
  }

  // Add the script
  const script = document.createElement('script');
  script.src = '/assets/js/accordions.js';
  script.async = true;
  document.body.appendChild(script);

  // RANGE.JS
  // Remove existing script if it exists
  const existingScript_range = document.querySelector("script[src='/assets/js/range.js']");
  if (existingScript_range) {
    existingScript_range.remove();
  }

  // RANGE.JS
  // Add the script
  const script_range = document.createElement('script');
  script_range.src = '/assets/js/range.js';
  script_range.async = true;
  document.body.appendChild(script_range);

  // Remove existing script if it exists
  const existingScript_range_designSpring = document.querySelector(
    "script[src='/assets/js/range-design-sprint.js']",
  );
  if (existingScript_range_designSpring) {
    existingScript_range_designSpring.remove();
  }

  // Add the script
  const script_range_design_sprint = document.createElement('script');
  script_range_design_sprint.src = '/assets/js/range-design-sprint.js';
  script_range_design_sprint.async = true;
  document.body.appendChild(script_range_design_sprint);
};
