exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-download-tsx": () => import("./../../../src/pages/about/download.tsx" /* webpackChunkName: "component---src-pages-about-download-tsx" */),
  "component---src-pages-about-legal-tsx": () => import("./../../../src/pages/about/legal.tsx" /* webpackChunkName: "component---src-pages-about-legal-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-products-contract-tsx": () => import("./../../../src/pages/products/contract.tsx" /* webpackChunkName: "component---src-pages-products-contract-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-phonesystem-tsx": () => import("./../../../src/pages/products/phonesystem.tsx" /* webpackChunkName: "component---src-pages-products-phonesystem-tsx" */),
  "component---src-pages-products-portal-tsx": () => import("./../../../src/pages/products/portal.tsx" /* webpackChunkName: "component---src-pages-products-portal-tsx" */),
  "component---src-pages-products-realestate-tsx": () => import("./../../../src/pages/products/realestate.tsx" /* webpackChunkName: "component---src-pages-products-realestate-tsx" */),
  "component---src-pages-products-sales-tsx": () => import("./../../../src/pages/products/sales.tsx" /* webpackChunkName: "component---src-pages-products-sales-tsx" */),
  "component---src-pages-products-start-tsx": () => import("./../../../src/pages/products/start.tsx" /* webpackChunkName: "component---src-pages-products-start-tsx" */),
  "component---src-pages-services-designsprint-tsx": () => import("./../../../src/pages/services/designsprint.tsx" /* webpackChunkName: "component---src-pages-services-designsprint-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-operationalsupport-tsx": () => import("./../../../src/pages/services/operationalsupport.tsx" /* webpackChunkName: "component---src-pages-services-operationalsupport-tsx" */),
  "component---src-pages-services-solution-strategy-tsx": () => import("./../../../src/pages/services/solution-strategy.tsx" /* webpackChunkName: "component---src-pages-services-solution-strategy-tsx" */)
}

